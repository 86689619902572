const LayoutFiscal = () => import('../layout/LayoutFiscal.vue');

export default [{
  path: '/fiscal',
  component: LayoutFiscal,
    meta: {
        forAuth : true,
    },
  children: [{
    path: 'gerais',
      children: [{
          path: 'pessoas',
          component: () => import('../components/modulos/utilitarios/pessoas/PessoasTable.vue')
      }]
  },{
      path: 'integracao',
      component: () => import('../components/modulos/fiscal/IntegracaoTable.vue')
  },{
      path: 'integracao/criar',
      component: () => import('../components/modulos/fiscal/Integracao.vue'),
      name: 'integracaoCriar'
  },{
      path: 'integracao/:id',
      component: () => import('../components/modulos/fiscal/Integracao.vue'),
      name: 'integracaoEditar'
  },{
      path: 'entrada',
      component: () => import('../components/modulos/fiscal/EntradaTable.vue')
  },{
      path: 'entrada/criar',
      component: () => import('../components/modulos/fiscal/Entrada.vue'),
      name: 'entradaCriar'
  },{
      path: 'entrada/:id',
      component: () => import('../components/modulos/fiscal/Entrada.vue'),
      name: 'entradaEditar'
  },{
      path: 'tabelas/federal',
      component: () => import('../components/modulos/mercadorias/MercadoriaTable.vue')
  },{
      path: 'tabelas/federal/criar',
      component: () => import('../components/modulos/mercadorias/Mercadoria.vue'),
      name: 'federalCriar'
  },{
      path: 'tabelas/federal/:id',
      component: () => import('../components/modulos/mercadorias/Mercadoria.vue'),
      name: 'federalEditar'
  },{
      path: 'tabelas/estadual',
      component: () => import('../components/modulos/mercadorias/MercadoriaTable.vue')
  },{
      path: 'tabelas/estadual/criar',
      component: () => import('../components/modulos/mercadorias/Mercadoria.vue'),
      name: 'estadualCriar'
  },{
      path: 'tabelas/estadual/:id',
      component: () => import('../components/modulos/mercadorias/Mercadoria.vue'),
      name: 'estadualEditar'
  },{
      path: 'tabelas/municipal',
      component: () => import('../components/modulos/mercadorias/MercadoriaTable.vue')
  },{
      path: 'tabelas/municipal/criar',
      component: () => import('../components/modulos/mercadorias/Mercadoria.vue'),
      name: 'municipalCriar'
  },{
      path: 'tabelas/municipal/:id',
      component: () => import('../components/modulos/mercadorias/Mercadoria.vue'),
      name: 'municipalEditar'
  },{
      path: 'parametro/:id',
      component: () => import('../components/modulos/fiscal/Parametro.vue'),
      name: 'FiscalParametroEditar'
  },{
      path: 'parametro',
      component: () => import('../components/modulos/fiscal/ParametroTable.vue'),
      name: 'FiscalParametroTabela'
  }]
}]
