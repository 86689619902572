//import Vue from 'vue'
//import VueResource from 'vue-resource'
import { route } from 'quasar/wrappers'
import { pinia } from '../main'
import { defineComponent, ref, onMounted, watch, reactive, defineAsyncComponent } from 'vue'
import { useAuthorizationStore } from '~stores/modulos/auth/authorization.js'
import { useEmpresaStore } from '~stores/modulos/empresas.js'



import { createRouter, createMemoryHistory, createWebHistory } from 'vue-router'

//import Meta from 'vue-meta'
import store from '~stores/index'
import NotFound from '../components/NotFound.vue'

import globals from '../globals'

import gerenciamentoRoutes from './gerenciamento'

import academiaRoutes from './academia'
import agendaRoutes from './agenda'
import contabilidadeRoutes from './contabilidade'
import dispositivosRoutes from './dispositivos'
import faturamentoRoutes from "./faturamento"
import financeiroRoutes from "./financeiro"
import fiscalRoutes from "./fiscal"
import ajudaRoutes from './ajuda'
import perfilRoutes from './perfil'
import configuracoesRoutes from './configuracoes'
import principalRoutes from './principal'
import mercadoriasRoutes from './mercadorias'
import servicosRoutes from './servicos'
import orcamentoRoutes from './orcamento'
import provedorRoutes from './provedor'
import imoveisRoutes from './imoveis'
import crmRoutes from './crm'
import pdvRoutes from './pdv'
import fazendaRoutes from './fazenda'
import tagRoutes from './tag'

//const TagRouter = () => import('./tag')

import hotelRoutes from './hotel'
import plusRoutes from './plus'
import erpRoutes from './erp'
import visitasRoutes from './visitas'
import turismoRoutes from './turismo'
import utilitariosRoutes from './utilitarios'
import authRoutes from './auth/auth'
//import Auth from '../components/packages/auth/Auth'

// Layouts
// import Layout1 from '@/layout/Layout1'
//Vue.use(VueResource)
//Vue.use(VueRouter)
//Vue.use(Meta)
//Vue.use(Auth)


// Essas linhas servem para não precisar mais ficar chamando os tokens a cada chamado do AXIOS
// axios.interceptors.request.use(
//     (config) => {
//         let token = localStorage.getItem('token_sanctum');

//         if (token) {
//             config.headers['Authorization'] = `Bearer ${token}`;
//         }

//         return config;
//     },

//     (error) => {
//         return Promise.reject(error);
//     }
// );

//import Echo from 'laravel-echo'

//window.Pusher = require('pusher-js');
/*
window.Echo = new Echo({
    authEndpoint: "/api/broadcasting/auth",
    broadcaster: 'pusher',
    key: process.env.MIX_PUSHER_APP_KEY,
    cluster: process.env.MIX_PUSHER_APP_CLUSTER,
    encrypted: false,
    auth: {headers: {Authorization: "Bearer " + localStorage.getItem('token')}}
});
*/
const routes = [
    // Default route
    { path: '', redirect: '/login' }
]
    .concat(academiaRoutes)
    .concat(agendaRoutes)
    .concat(contabilidadeRoutes)
    .concat(dispositivosRoutes)
    .concat(faturamentoRoutes)
    .concat(financeiroRoutes)
    .concat(fiscalRoutes)
    .concat(gerenciamentoRoutes)
    .concat(hotelRoutes)
    .concat(imoveisRoutes)
    .concat(mercadoriasRoutes)
    .concat(plusRoutes)
    .concat(erpRoutes)
    .concat(principalRoutes)
    .concat(provedorRoutes)
    .concat(servicosRoutes)
    .concat(orcamentoRoutes)
    .concat(visitasRoutes)
    .concat(turismoRoutes)
    .concat(ajudaRoutes)
    .concat(perfilRoutes)
    .concat(configuracoesRoutes)
    .concat(utilitariosRoutes)
    .concat(crmRoutes)
    .concat(pdvRoutes)
    .concat(fazendaRoutes)
    .concat(tagRoutes)
    .concat(authRoutes)

// 404 Not Found page
routes.push({
    path: '/teste',
    component: NotFound
})

// const router = new VueRouter({
//     base: '/',
//     mode: 'history',
//     routes: ROUTES
// });

const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHistory(),
    routes // short for `routes: routes`
  })
  

router.beforeEach(
    (to, from, next) => {
        const AuthorizationStore = useAuthorizationStore(pinia)
        const EmpresaStore = useEmpresaStore(pinia)
        if (to.matched.some(record => record.meta.forVisitors)) {
            if (AuthorizationStore.loggedIn) {
                next({
                    path: '/principal'
                })
            } else next()
        }
        else if (to.matched.some(record => record.meta.forAuth)) {
            if (!AuthorizationStore.loggedIn) {
                next({
                    path: '/login'
                })
            } else {
                if (EmpresaStore.items.length == 0) {
                    EmpresaStore.loadEmpresas()
                        .then(response => {
                            EmpresaStore.markAsEmpresa({ uuid: EmpresaStore.items[0].uuidesquema.uuid })
                                .then(response => {
                                    AuthorizationStore.getMe()
                                        .then(response => {
                                            next()
                                        })
                                })
                                .catch(error => {
                                    console.log(error);
                                })
                        })
                        .catch(error => {
                            console.log(error);
                            if (error.status === 401) {
                                AuthorizationStore.logout()
                                router.push('/login')
                            }
                        })
                } else if (EmpresaStore.items.length == 1) {
                    EmpresaStore.markAsEmpresa({ uuid: EmpresaStore.items[0].uuidesquema.uuid })
                        .then(response => {
                            AuthorizationStore.getMe()
                                .then(response => {
                                    next()
                                })
                        })
                        .catch(error => {
                            console.log(error);
                        })
                } else {
                    EmpresaStore.markAsEmpresa({ uuid: EmpresaStore.empresa.uuid })
                        .then(response => {
                            AuthorizationStore.getMe()
                                .then(response => {
                                    next()
                                })
                        })
                        .catch(error => {
                            console.log(error);
                        })
                }
            }
        }
        else next()
    }
)

router.afterEach(() => {
  // Remove initial splash screen
  const splashScreen = document.querySelector('.app-splash-screen')
  if (splashScreen) {
      splashScreen.style.opacity = 0
      setTimeout(() => splashScreen && splashScreen.parentNode && splashScreen.parentNode.removeChild(splashScreen), 300)
  }

  // On small screens collapse sidenav
  if (window.layoutHelpers && window.layoutHelpers.isSmallScreen() && !window.layoutHelpers.isCollapsed()) {
    setTimeout(() => window.layoutHelpers.setCollapsed(true, true), 10)
  }

  // Scroll to top of the page
  globals().scrollTop(0, 0)
})

router.beforeEach((to, from, next) => {
  // Set loading state
  document.body.classList.add('app-loading')

  // Add tiny timeout to finish page transition
  setTimeout(() => next(), 10)
})


export default router